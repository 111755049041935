:root {
  --color-1: #f28f3b;
  --color-2: #ef5350;
  --color-3: #398bf7;
  --color-4: #39d5cf;
}

.card {
  /* padding: 1rem; */
  border-radius: 1rem;
  background-color: white;
  margin: 1rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 1%);
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem;
}
.desc {
  margin: 0.5rem 0;
}
.descHead {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}
.descBody {
  font-size: 1rem;
  margin: 0.2rem 0;
}
.heading {
  background-color: var(--color-1);
  padding: 1rem;
  border-radius: 1rem 1rem 0 0;
}
.headingTitle {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  color: white;
}
.detailsButton {
  margin: 0;
  width: calc(100% - 6rem);
  margin: 0 3rem 1.5rem;
  background-color: #398bf7;
  color: white;
  font-weight: 600;
  padding: 0.5rem;
  border: 0;
  border-radius: 0.5rem;
}
