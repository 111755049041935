.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  max-width: 600px;
  padding: 1rem;
  border-radius: 1rem;
  min-height: 600px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
  margin: 010vh;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f6f9;
}

.container h1,
.container p {
  text-align: center;
}

.logout{
  cursor:pointer;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.4);
  padding: 8px;
  display: flex;
  margin-right: -58%;
  
}
button {
  width: 80%;
  margin: 0 10%;
  margin-top: 50px;
}

.inputField {
  width: 100%;
  margin: 10px;
}
.inputField > div {
  width: 80%;
  margin: 0 10%;
}
label {
  width: 80%;
  margin: 0 10%;
  margin-top: 10px;
}
.image {
  height: 5rem;
}

.container button {
  margin-top: 50px;
}

.footerText {
  margin-top: 5rem;
  display: flex;
  align-items: center;
}

.footerImage {
  height: 2rem;
}

@media only screen and (max-width: 700px) {
  .container {
    margin: 2rem;
    min-height: 65vh;
  }
}

.loadingBox {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 100;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingText {
  background-color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 0.2rem;
}
