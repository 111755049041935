.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  max-width: 600px;
  padding: 1rem;
  border-radius: 1rem;
  min-height: 600px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
  margin: 1rem;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f6f9;
}
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f6f9;
  min-height: 100vh;
  width: 100%;
}
.container h1,
.container p {
  text-align: center;
}
