.container {
  background: white;
  padding: 1rem;
}

.image {
  height: 5rem;
}

.line {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1rem;
}
.activeLink,
.passiveLink {
  text-decoration: none !important;
  color: black;
  display: flex;
  align-items: center;
  padding: 0.75rem 0.5rem;
  gap: 0.25rem;
}
.activeLink p,
.passiveLink p {
  margin: 0;
}

.activeLink {
  background-color: #f28f3b;
  color: white;
  border-radius: 0.5rem;
}
