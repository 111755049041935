.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #feedde;
  height: 100vh;
  background-image: url(../../images/splash_background.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-between;
}

.image {
  margin-top: 7rem;
  height: 13vh;
}

.text {
  font-size: 1.5rem;
  text-align: center;
  /* flex: 1; */
}

.button {
  margin-bottom: 5rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.button img {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 50%;
}

.button span {
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

.main a {
  text-decoration: none;
}
