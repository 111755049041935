.dropzone {
  margin: 2rem;
  padding: 4rem 2rem;
  border-color: rgba(112, 112, 112, 0.667);
  border-width: 2px;
  border-style: dashed;
  border-radius: 10px;
  text-align: center;
  width: calc(100% - 6rem);
}

.dropzone p {
  margin: 0;
}

.textLarge {
  font-weight: bold;
  font-size: larger;
}
