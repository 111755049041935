.container {
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(144, 144, 144, 0.667);
  width: calc(100% - 4rem);

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.container * {
  padding: 0;
  margin: 0;
}

.container span {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.container P {
  font-weight: bold;
}
