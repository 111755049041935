.main {
  background: #f4f6f9;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}
.cardsRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.rowTitle {
  padding-top: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: #000000;
  margin: 0 1rem;
}
.body {
  width: 100%;
}
