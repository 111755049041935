.inputField input,
.inputField select,
.inputField textarea {
  background: #fff;
  border: 1px solid #868fa3aa;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-weight: 500;
  font-size: 0.875rem;
  /* margin: 10px; */
  width: 100%;
  resize: vertical;
}
.inputField input:focus,
.inputField select:focus,
.inputField textarea:focus {
  border: 1px solid #868fa3aa;
}
.toggleSwitch {
  margin: 10px;
}
.switchInput {
  margin: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switchInput:checked + .slider {
  background: linear-gradient(90deg, #ff6648 0%, #dd2476 100%);
}
.switchInput:focus + .slider {
  box-shadow: 0 0 1px #ff6648;
}
.switchInput:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
@media only screen and (max-width: 700px) {
  .inputField input,
  .inputField select,
  .inputField textarea {
    font-weight: 400;
    font-size: 0.875rem;
  }
}
