.main {
  background: #f4f6f9;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}
.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.profileImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileImageContainer img {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
}
.label {
  margin: 0;
  color: rgb(110, 110, 110);
}
.text {
  color: black;
  margin: 0;
  /* margin-bottom: 1rem; */
  margin-top: 0.5rem;
}
