.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 1rem;
  border: 1px solid rgb(179, 179, 179);
  padding: 1rem;
  gap: 1.5rem;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.title h2 {
  padding: 0;
  margin: 0;
}

.title img {
  height: 1.5rem;
  width: 1.5rem;
}

.title span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.editButton {
  background-color: #f28f3b;
  color: white;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
}

.editButton img {
  height: 1rem;
}

.imageBackground {
  background-color: #f2903b4b;
  padding: 0.5rem;
  border-radius: 100%;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
