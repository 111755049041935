.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container img {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}
